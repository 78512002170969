@import '../styles/layoutCommon.scss';

// .backgroundSwatch {
// 	color: black;
// 	height: 100%;
// }

.root {
	& {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;

		background: no-repeat center center fixed; 
		background-size: cover;

		.centeringBlock {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
	}

	

	.banner {
		margin: 1rem 1rem 2rem;
		border-bottom: .25rem solid white;
		font-size: 2rem;
		font-weight: bold;

		.d {
			color: white;
		}

		.ecidr {
			color: rgba(255,255,255,0.5);
		}
	}

	.buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0 1rem;
		max-width: 15rem;

		a {
			color: white;
			font-weight: 700;
			border: .2rem solid white;
			padding: 1rem 2rem;
			width: 100%;
			margin: 1.5rem 0rem;
			text-align: center;
			background: rgba(0,0,0,0.33);


			&, &:active, &:hover {
				text-decoration: none;
			}

			&:hover, &:active {
				color: lighten($color: $brand-third, $amount: 67%);
				background: transparentize($color: $brand-third, $amount: .75);
				// border-color: $brand-primary;
				border-color: lighten($color: $brand-third, $amount: 65%);
			}

			svg {
				margin-left: .5rem;
			}
		}
	}

	.infoLink {
		margin-top: 1rem;
		color: rgba(255,255,255,0.77);
	}
}