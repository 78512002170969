.root {
	padding: 1.25rem;


	h1 {
		margin: 1rem 1.5rem 0rem 0;
		// border-bottom: .25rem solid white;
		font-size: 2rem;
		font-weight: bold;
	}
	
	p {
		line-height: 1.1;
		// text-indent: 0rem;
		// text-align: justify;
		a {
			font-weight: bold;
			text-decoration: underline;
			color: white;
		}
	}

	p:first-of-type {
		padding-top: 1rem;
		border-top: .25rem solid white;
		margin-top: .75rem;
	}

	p:last-child {
		padding-bottom: 1rem;
		border-bottom: .25rem solid white;
		margin-bottom: 1rem;
	}

}