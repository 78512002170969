@import '../styles/layoutCommon.scss';

@mixin basic-button {
	display: flex;
	justify-content: space-between;
	align-items: center;

	border: .125rem solid white;
	background: rgba(0,0,0,0.33);
	color: white;

	&, &:active, &:hover {
		text-decoration: none;
	}

	&:hover, &:active {
		color: lighten($color: $brand-third, $amount: 67%);
		background: transparentize($color: $brand-third, $amount: .75);
		border-color: lighten($color: $brand-third, $amount: 65%);
	}

	line-height: 1;
}

.root,
.scenarioRoot,
.requestRoot {
	max-width: 30rem;
	width: 100%;
	padding: 1.25rem;

	display: flex;
	flex-direction: column;

	max-width: 24rem;

	h1 {
		margin: .5rem 1.5rem 1rem 0;
		font-size: 2rem;
		font-weight: bold;
		text-align: center;
		width: 100%;
	}
	
	p {
		line-height: 1.1;
		a {
			font-weight: bold;
			text-decoration: underline;
			color: white;
		}
	}

	p:first-of-type {
		padding-top: 1rem;
		border-top: .25rem solid white;
		margin-top: .75rem;
	}

	p:last-child {
		padding-bottom: 1rem;
		border-bottom: .25rem solid white;
		margin-bottom: 1rem;
	}


	.request {
		font-weight: 700;
		
		@include basic-button();

		border-radius: 50%;
		width:  2.5rem;
		height: 2.5rem;

		margin: 1rem;

		align-self: center;

		justify-content: center;
		
		svg {
			// make the "+" more centered
			// transform: translate(0.05rem, 0.05rem);
		}
	}


	// future styling...core styles defined below
	.optionRoot {
		.title {	
		}

		.status {
		}
	}
}

.requestRoot {

	p:first-of-type {
		border: none;
		padding-top: 0;
		margin-top: 1rem; //.625rem;
	}

	.requestInput  {
		border: none;
		// border-top: .125rem solid white;
		border-bottom: .125rem solid white;
		background: transparent;
		// background: rgba(0,0,0,0.33);
		padding: .75rem 0;
		line-height: 1;

		outline: none;
		color: white;
		
		&::placeholder {
			color: rgba(255,255,255,0.5);
		}
	}

	.errorMsg {
		color: rgba(255,255,255,0.75);
		font-size: 0.8rem;
	}


}

.scenarioRoot {
	.target {
		// @include basic-button();

		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 1rem;
		// margin: 1rem 0;
		line-height: 1;

		.label {
			font-size: .7rem;
		}

		.value {
			font-weight: 900;
			font-size: 1.2rem;
		}
	}
}

.optionListRoot,
.interactionListRoot,
.choiceListRoot {
	display: flex;
	flex-direction: column;
		
	.optionRoot,
	.interactionRoot,
	.choiceRoot {
		@include basic-button();

		max-width: 30rem;
		width: 100%;

		font-size: 0.8rem;
		padding: .5rem;
		margin: 0 0 0.5rem;

		.title {
			
		}

		.status {
			background: rgba(0,0,0,0.33);
			padding: .5rem;
			border-radius: .25rem;
			margin-left: .5rem;
		}
	}

	.interactionRoot {
		flex-direction: column;
	}

	.choiceRoot {
		border: none;
		margin: 0;
		border-radius: .5rem;
		justify-content: space-between;

		.votes {
			margin-left: .5rem;
			opacity: 0.6;
			font-size: 0.7rem;
		}
	}


	.choiceRoot + .choiceRoot {
		margin-top: .5rem;
	}
}