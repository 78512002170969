.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 100%;

	background: no-repeat center center fixed; 
	background-size: cover;

	color: white;
}